<template>
    <div>
  <header class="header">
         <a href=""> <img :src="logo" class="logo" alt="ArMBD"></a>
        <input class="side_menu" type="checkbox" id="side_menu"/>
        <label class="hamb" for="side_menu"><span class="hamb_line"></span></label>
        <nav class="nav">
            <ul class="menu">
                    <li><a href=#empresa>Empresa</a></li>
                    <li><ModalVue/></li>
                    <li><a :href="loja" target="_blank">Loja</a></li>

                    <li><a href=#contato>Contato</a></li>
                    <li><a href=#revenda>Revenda</a></li>

                    <li class="btn"><a :href="contato" target="_blank">Fale com especialista</a></li>
                    
                   
            </ul>
        </nav>
    </header>
    </div>
</template>

<script>
import ModalVue from './Modal.vue'

export default{
    name:'headerVue',
    components:{
            ModalVue,
        },

    data(){
        return{
            logo:'./images/logo.png',
        termos:'/assets/dpn-ar-mbd-solucoes-digitais.pdf',
            loja:'https://armbd.gfsis.com.br/gestaofacil/loja/index',
            contato:'https://api.whatsapp.com/send?phone=5512982295756',
        }
        
    }
}
</script>


<style scoped>


body{
    font-family: "Poppins", sans-serif;
}
a{
    text-decoration: none;
    color:#36383F;

}
ul{
    list-style: none;
    font-size: 1.2em;
    margin-top: 6%;
}

.btn{
    border: solid 1px #9370DB;
    color: #9370DB;
    background-color: #fff;
    border-radius: 7px;
    text-decoration: none;
    padding: 7px;
    margin-right: 50px;
    font-weight: bold;
    margin-top: -10px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
}
.btn a{
    text-decoration: none;
    color: #7C489B !important;
}
.btn a:hover{
    text-decoration: none;
    color: #fff !important;
}
.btn:hover{
    border: none;
    color: #fff !important;
    background-color: #7C489B;

}
li{
    margin-right: 35px;
    cursor: pointer;

}
li:hover{
    color: #9370DB;
}
.header{
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0px var(--gray);
    position: sticky;
    top: 0;
    width: 100%;
}
.logo{
    margin-left: 20px;
    float: left;
    height: 100px;
    width: 370px;
}
.nav{
    width: 100%;
    overflow: hidden;
    color:#36383F;
    /* margin-right: 11%; */
}
.menu a{
    display: block;
    color:#36383F;
}
.menu a:hover{
    background-color: var(--gray);
}
.nav{
    max-height: 0;
    transition: max-height .5s ease-out;

}
.hamb{
    cursor: pointer;
    float: right;
    padding: 40px 20px;
}

.hamb_line {
    background: #36383F;
    display: block;
    height: 2px;
    position: relative;
    width: 24px;
} 
.hamb_line::before,
.hamb_line::after{
    background: #36383F;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
    color:red;
}
.hamb_line::before{
    top: 5px;
}
.hamb_line::after{
    top: -5px;
}

.side_menu {
    display: none;
} 


.side_menu:checked ~ nav{
    max-height: 100%;
}
.side_menu:checked ~ .hamb .hamb_line {
    background: transparent;
}
.side_menu:checked ~ .hamb .hamb_line::before {
    transform: rotate(-45deg);
    top:0;
}
.side_menu:checked ~ .hamb .hamb_line::after {
    transform: rotate(45deg);
    top:0;
}

@media (min-width: 768px) {
    .nav{
        max-height: none;
        top: 0;
        position: relative;
        float: right;
        width: fit-content;
    }
    .menu li{
        float: left;
    }
    .menu a:hover{
        background-color: transparent;
        color: var(--gray);

    }

    .hamb{
        display: none;
    }
}

@media only screen and (max-width: 869px)  {
  .header{
    background-color: #ffffff;
    box-shadow: 1px 1px 5px 0px var(--gray);
    position: sticky;
    top: 0;
    width: 100%;
  
}
img{
    float: left;
    height: 100px;
    top: 15% !important;
}

.logo{
    margin-left: 20px;
    float: left;
    height: 100px;
    width: 270px;
}

.btn{
    margin-top: 7px;
    width: 70%;
}

li{
    margin-top:10px;
}
} 
</style>